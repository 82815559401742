<template>
  <div class="mr-5">
    <div class="vs-con-loading__container" :id="'clausula' + faturamento">
      <div
        class="w-full vs-con-loading__container"
        :id="'tipo_faturamento' + faturamento"
      >
        <vs-divider
          border-style="dotted"
          color="dark"
          class="py-0 mt-0 font-bold"
          >Parâmetros</vs-divider
        >
        <vs-row
          vs-type="flex"
          vs-w="12"
          vs-align="flex-start"
          vs-justify="space-between"
        >
          <vs-col
            vs-type="flex"
            vs-w="9"
            vs-align="flex-start"
            vs-justify="center"
          >
            <vs-textarea
              class="mx-2"
              label="Observação"
              color="dark"
              name="obs"
              v-model="tipo_faturamento.obs"
            ></vs-textarea>
          </vs-col>
          <vs-col
            vs-align="flex-start"
            vs-justify="center"
            vs-type="flex"
            vs-w="3"
          >
            <div class="w-full">
              <vs-col
                vs-align="center"
                vs-justify="center"
                vs-type="flex"
                vs-w="12"
              >
                <vs-input
                  type="number"
                  class="w-full mt-2 mx-4"
                  color="dark"
                  label-placeholder="Limite do Desconto"
                  min="0"
                  v-model="tipo_faturamento.limite_desconto"
                />
              </vs-col>
              <vs-col
                vs-align="center"
                vs-justify="flex-end"
                vs-type="flex"
                vs-w="12"
              >
                <vs-button
                  size="small"
                  class="w-full mx-4 mt-2"
                  color="warning"
                  type="relief"
                  icon="create"
                  @click="editarTipoFaturamento()"
                  >Atualizar</vs-button
                >
              </vs-col>
            </div>
          </vs-col>
        </vs-row>
      </div>
      <vs-divider border-style="dotted" color="dark" class="py-0 my-0 font-bold"
        >Cláusulas</vs-divider
      >
      <vs-row
        vs-type="flex"
        vs-align="flex-end"
        vs-justify="flex-end"
        vs-w="12"
      >
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-align="flex-end"
          vs-justify="flex-end"
        >
          <div>
            <label style="color: black; font-size: 0.85rem" class="pl-2"
              >Controle</label
            ><br />
            <el-select
              v-model="filtro.controle"
              clearable
              style="min-width: 300px"
              @change="getClausulas()"
              name="tipo_faturamento"
              :popper-append-to-body="true"
              filterable
            >
              <el-option
                v-for="controle in controles"
                :key="controle.id"
                :label="controle.nome"
                :value="controle.id"
              >
              </el-option>
            </el-select>
          </div>
        </vs-col>
      </vs-row>
      <div v-if="clausulas.length == 0">
        <vx-card no-shadow class="p-1">
          <vs-col
            class="mt-5"
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
          </vs-col>
          <vs-col
            class="mt-2"
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <span class="font-semibold">
              {{
                filtro.controle ? "NÃO HÁ CLÁUSULAS" : "SELECIONE UM CONTROLE"
              }}</span
            >
          </vs-col>
        </vx-card>
      </div>
      <div class="w-full" v-else>
        <VuePerfectScrollbar
          class="scroll-clausulas-lista"
          :settings="settings"
        >
          <draggable
            :disabled="!$atividades.permissoes.includes(14)"
            :list="clausulas"
            @change="indexControlesClausula()"
            :key="reRenderClausulas"
            group="clausula"
            class="cursor-move"
          >
            <vx-card
              card-border
              no-shadow
              class="my-2"
              v-for="(data, index) in clausulas"
              :key="index"
            >
              <vs-row vs-type="flex" vs-justify="space-between">
                <vs-col
                  vs-w="12"
                  vs-align="flex-start"
                  vs-justify="flex-start"
                  vs-type="flex"
                >
                  <h6>Clausula N{{ data.numero }}</h6>
                </vs-col>
              </vs-row>

              <vs-row vs-type="flex" vs-justify="space-between">
                <vs-col
                  vs-w="10"
                  vs-align="center"
                  vs-justify="flex-start"
                  vs-type="flex"
                >
                  <h4
                    style="
                      text-align: justify;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                    "
                  >
                    {{ data.clausula }}
                  </h4>
                </vs-col>
                <vs-col
                  vs-w="2"
                  vs-align="center"
                  vs-justify="flex-end"
                  vs-type="flex"
                  class="mb-3"
                >
                  <EditButton
                    :disabled="!$atividades.permissoes.includes(14)"
                    @func="editarClausula(data)"
                  ></EditButton>
                </vs-col>
              </vs-row>
              <vs-row vs-type="flex" vs-justify="space-between">
                <vs-col
                  vs-w="12"
                  vs-align="flex-start"
                  vs-justify="flex-start"
                  vs-type="flex"
                >
                  <div class="w-full">
                    <span
                      v-for="(item, index) in data.controlesClausula"
                      :key="index"
                    >
                      <Badge
                        :text="item.controle"
                        size="small"
                        color="dark"
                      ></Badge>
                    </span>
                  </div>
                </vs-col>
              </vs-row>
            </vx-card>
          </draggable>
        </VuePerfectScrollbar>
        <component
          v-bind:is="ModalEditar"
          :key="reRenderModalEditar"
          :clausula="clausulaSelect"
          @mudarStatus="mudarStatus"
          @excluirControleClausula="excluirControleClausula"
          @update="getClausulas"
        />
      </div>
    </div>
  </div>
</template>
<script>
import EditarClausula from "./editar"
import draggable from "vuedraggable"
import components from "@/components/default/exports.js"
export default {
  props: {
    tipo_faturamento: {},
    faturamento: null
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
        height: 1000
      },
      clausulas: [],
      clausulaSelect: {},
      originalData: {},
      ModalEditar: "",
      reRenderModalEditar: 0,
      controles: [],
      filtro: {},
      reRenderClausulas: 0
    }
  },
  methods: {
    async getClausulas () {
      await this.$vs.loading({
        container: "#clausula" + this.faturamento,
        scale: 0.6
      })
      try {
        this.clausulas = await this.$http.post(
          `getClausulasControles`, { id_controle: this.filtro.controle, id_tipo_faturamento: this.faturamento }
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
      await this.$vs.loading.close(
        "#clausula" + this.faturamento + " > .con-vs-loading"
      )
    },
    async getControles () {
      try {
        this.controles = await this.$http.get("controle")
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async indexControlesClausula () {
      let i = 1
      await this.clausulas.forEach(clausula => {
        clausula.numero = i
        this.update(clausula)
        i++
      })
      await this.notify()
    },
    async update (clausula) {
      try {
        await this.$http.post("updateControlesClausula", clausula)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async excluirControleClausula (clausula) {
      try {
        await this.$http.post("excluirControlesClausula", clausula)
        await this.getClausulas()
        await this.indexControlesClausula()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async editarTipoFaturamento () {
      await this.$vs.loading({
        container: "#tipo_faturamento" + this.faturamento,
        scale: 0.6
      })
      try {
        this.tipo_faturamento = await this.$http.put(`tipo_faturamento/${this.faturamento}`, this.tipo_faturamento)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#tipo_faturamento" + this.faturamento + " > .con-vs-loading"
        )
      }
    },
    async editarClausula (clausula) {
      this.clausulaSelect = clausula
      this.ModalEditar = "EditarClausula"
      this.reRenderModalEditar += 1
    },
    async mudarStatus () {
      this.$vs.notify({
        title: "Opção indisponível!",
        text: "A opção de mudar status não é disponível para as cláusulas, somente exclusão!",
        color: "warning"
      })
    },
    async notify () {
      this.$vs.notify({
        title: "Salvando a ordem das cláusulas...",
        text: "A ordem é salva automáticamente!",
        color: "success"
      })
    }
  },
  async mounted () {
    this._beforeEditingCache = Object.assign({}, this.tipo_faturamento)
    await this.getClausulas()
    await this.getControles()
  },
  components: {
    ...components,
    EditarClausula,
    draggable
  }
};
</script>
<style >
.scroll-clausulas-lista {
  height: 55vh;
}
</style>
