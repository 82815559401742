<template>
  <div class="w-full">
    <SuccessButton
      :disabled="!$atividades.permissoes.includes(14)"
      @func="errors.clear(), (modalCadastrar = true)"
    ></SuccessButton>
    <SideBar
      size="large"
      title="Cadastrar Cláusula"
      @hide="modalCadastrar = false"
      :active="modalCadastrar"
      @success="submitForm()"
    >
      <div slot="body" class="w-full vs-con-loading__container" id="loading">
        <vs-row
          vs-type="flex"
          vs-align="center"
          vs-justify="center"
          vs-w="12"
          class="mt-3"
        >
          <vs-col vs-w="12" vs-tpe="flex" vs-align="center" vs-justify="center">
            <div class="w-full p-0">
              <vs-textarea
                style="text-align: justify"
                height="250"
                v-model="clausula.clausula"
                class="p-0 m-0 w-full"
                name="clausula"
                label="Cláusula"
                v-validate="'required'"
              />
              <span
                class="text-danger mb-2 text-sm"
                v-show="errors.has('clausula')"
              >
                {{ $validators.empty }}</span
              >
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          vs-align="center"
          vs-justify="center"
          vs-w="12"
          class="mt-3"
        >
          <vs-col vs-w="6" vs-tpe="flex" vs-align="center" vs-justify="center">
            <div class="mx-1">
              <label style="color: black; font-size: 0.85rem" class="pl-2"
                >Tipo Faturamento</label
              ><br />
              <el-select
                v-model="clausula.id_tipo_faturamento"
                clearable
                class="w-full"
                v-validate="'required'"
                name="tipo_faturamento"
                :popper-append-to-body="false"
                filterable
              >
                <el-option
                  v-for="data in tipo_faturamento"
                  :key="data.id"
                  :label="data.nome"
                  :value="data.id"
                >
                </el-option>
              </el-select>
              <vs-row>
                <span
                  class="text-danger text-sm mt-1"
                  v-show="errors.has('tipo_faturamento')"
                >
                  {{ $validators.empty }}
                </span>
              </vs-row>
            </div>
          </vs-col>
          <vs-col vs-w="6" vs-tpe="flex" vs-align="center" vs-justify="center">
            <div class="mx-1">
              <label style="color: black; font-size: 0.85rem" class="pl-2"
                >Controles</label
              ><br />
              <el-select
                v-model="controlesClausula"
                clearable
                multiple
                collapse-tags
                class="w-full"
                name="tipo_faturamento"
                :popper-append-to-body="false"
                filterable
              >
                <el-option
                  v-for="controle in controles"
                  :key="controle.id"
                  :label="controle.nome"
                  :value="controle.id"
                >
                </el-option>
              </el-select>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"

export default {
  props: {
    active_tab: 0,
    tipo_faturamento: null
  },
  data () {
    return {
      clausula: { clausula: null, id_tipo_faturamento: null },
      controlesClausula: [],
      modalCadastrar: false,
      controles: []
    }
  },
  methods: {
    async submitForm () {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.cadastrarClausula()
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            })
          }
        })
      })
    },
    async cadastrarClausula () {
      await this.$vs.loading({ container: "#loading", scale: 0.6 })
      try {
        const res = await this.$http.post("contrato_clausulas", { clausula: this.clausula, controlesClausula: this.controlesClausula })
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'cadastrar',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Cadastro da cláusula N°' + res.id
        }
        await this.$logger(logData)
        this.$vs.notify(this.$notify.Success)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$emit("update")
        this.clausula = {}
        await this.$vs.loading.close("#loading > .con-vs-loading")
        this.modalCadastrar = false
      }
    },
    async getControles () {
      try {
        this.controles = await this.$http.get("controle")
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
  },
  async mounted () {
    await this.getControles()
  },
  components: { ...components }
};
</script>
