<template>
  <div class="w-full">
    <div ref="header flex">
      <vs-row class="mb-4" vs-type="flex" vs-w="12">
        <vs-col vs-justify="left" vs-align="left" vs-w="10">
          <h4 style="color: #b4aa99">Tipos de Faturamento</h4>
        </vs-col>
        <vs-col vs-justify="left" class="flex" vs-align="left" vs-w="2">
          <CadastrarClausula
            class="w-full"
            :tipo_faturamento="tipo_faturamento"
            @update="forceRender += 1"
          />
        </vs-col>
      </vs-row>
    </div>
    <div class="w-full" :key="forceRender" v-if="tipo_faturamento.length > 0">
      <vs-tabs
        position="left"
        alignment="fixed"
        class="tabs-shadow-none"
        id="profile-tabs"
        v-model="active_tab"
      >
        <vs-tab
          icon-pack="feather"
          v-for="(data, index) in tipo_faturamento"
          :key="index"
          :label="data.nome"
          class="w-full"
        >
          <!-- <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0 w-full">
            </div> -->
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0 w-full">
            <vx-card no-shadow>
              <ClausulasLista
                :tipo_faturamento="data"
                :faturamento="data.id"
              ></ClausulasLista>
            </vx-card>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>
<script>
import ClausulasLista from "./components/lista"
import CadastrarClausula from "./components/cadastrar"
export default {
  data () {
    return {
      active_tab: 0,
      forceRender: 0,
      tipo_faturamento: [],
    }
  },
  methods: {

    async getTipoFaturamento () {
      try {
        this.tipo_faturamento = await this.$http.get(`tipo_faturamento`)
        this.forceRender += await 1
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    }

  },
  async mounted () {
    this.$vs.loading()
    await this.getTipoFaturamento()
    this.$vs.loading.close()
  },
  components: {
    ClausulasLista,
    CadastrarClausula
  }
}
</script>
<style>
.con-slot-tabs {
  width: 100%;
}
.con-tab-ejemplo {
  width: 100%;
}

.vs-list--item .list-titles .vs-list--subtitle {
  font-size: 1.3rem;
}

.el-tabs__item.is-active {
  color: #ffca00 !important;
}

.el-tabs__item:hover {
  color: #ffca00 !important;
}

.el-tabs__active-bar {
  background-color: #ffca00 !important;
}
.col {
  width: auto;
}
.vs-tabs--content {
  padding: 0;
}
</style>
