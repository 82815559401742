
<template>
  <div>
    <!-- <EditButton @func="modalEdit = true"></EditButton> -->
    <SideBar
      size="large"
      title="Editar Cláusula"
      @hide="cancelar"
      :active="modalEdit"
      :disabled="!$atividades.permissoes.includes(15)"
      @success="submitForm()"
      :item="clausula"
      @excluir="excluirControleClausula"
      @status="mudarStatusControleClausula"
      :visualizar="blockButton"
      textSucces="Atualizar"
    >
      <div
        slot="body"
        class="vs-con-loading__container"
        :id="'loading' + this.clausula.id"
      >
        <vs-row
          vs-type="flex"
          vs-align="center"
          vs-justify="center"
          vs-w="12"
          class="mt-3"
        >
          <vs-col vs-w="12" vs-tpe="flex" vs-align="center" vs-justify="center">
            <div class="w-full p-0">
              <vs-textarea
                style="text-align: justify"
                height="250"
                v-model="clausula.clausula"
                class="p-0 m-0 w-full"
                name="clausula"
                label="Cláusula"
                v-validate="'required'"
              />
              <span
                class="text-danger mb-2 text-sm"
                v-show="errors.has('clausula')"
              >
                {{ $validators.empty }}</span
              >
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          vs-align="center"
          vs-justify="center"
          vs-w="12"
          class="mt-3"
        >
          <vs-col vs-w="12" vs-tpe="flex" vs-align="center" vs-justify="center">
            <div class="mx-1">
              <label style="color: black; font-size: 0.85rem" class="pl-2"
                >Controles</label
              ><br />
              <el-select
                v-model="controlesClausula"
                clearable
                multiple
                collapse-tags
                class="w-full"
                name="tipo_faturamento"
                :popper-append-to-body="false"
                filterable
              >
                <el-option
                  v-for="controle in controles"
                  :key="controle.id"
                  :label="controle.nome"
                  :value="controle.id"
                >
                </el-option>
              </el-select>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    clausula: {},
    tipo_faturamento: null
  },
  data () {
    return {
      clausulaEdit: {},
      modalEdit: false,
      controlesClausula: [],
      originalData: {},
      controles: [],
      controlesClausulaOrigin: [],
      blockButton: false
    }
  },
  methods: {
    async submitForm () {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.editClausula()
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            })
          }
        })
      })
    },
    async editClausula () {
      await this.$vs.loading({ container: "#loading" + this.clausula.id, scale: 0.6 })
      this.blockButton = true
      try {
        const res = await this.$http.put("contrato_clausulas/" + this.clausulaEdit.id, { clausula: this.clausulaEdit, controlesClausula: this.controlesClausula, controlesClausulaOrigin: this.controlesClausulaOrigin })
        let logObj = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'atualizar',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Edição da cláusula ' + this.tipo_faturamento + ' N°' + res.id
        }
        await this.$logger(logObj)
        this.$vs.notify(this.$notify.Success)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#loading" + this.clausula.id + " > .con-vs-loading")
        this.clausulaEdit = {}
        this.modalEdit = false
        this.blockButton = false
        await this.$emit("update")
      }
    },
    async excluirControleClausula () {
      this.$emit("excluirControleClausula", this.clausula)
    },
    async mudarStatusControleClausula () {
      this.$emit("mudarStatus")
    },
    async cancelar () {
      Object.assign(this.clausula, this._beforeEditingCache)
      this.originalData = this._beforeEditingCache = null
      this.modalEdit = false
    },
    async getControles () {
      try {
        this.controles = await this.$http.get("controle")
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getControlesOfClausula () {
      try {
        const res = await this.$http.post("getControlesOfClausula", { id_clausula: this.clausula.id, id_tipo_faturamento: this.clausula.id_tipo_faturamento })
        this.controlesClausulaOrigin = res
        this.controlesClausula = res
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    }
  },
  async mounted () {
    this.blockButton = true
    this._beforeEditingCache = await Object.assign({}, this.clausula)
    this.modalEdit = true
    this.clausulaEdit = await this.clausula
    await this.$vs.loading({ container: "#loading" + this.clausula.id, scale: 0.6 })
    await this.getControles()
    await this.getControlesOfClausula()
    await this.$vs.loading.close("#loading" + this.clausula.id + " > .con-vs-loading")
    this.blockButton = false
  },
  components: { ...components }
}
</script>
<style scoped>
.scroll-editar-clausula {
  max-height: 60vh !important;
}
</style>
